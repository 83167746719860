@import variables

// bootstrap
@import ~bootstrap/scss/bootstrap

// toastr
@import ~ngx-toastr/toastr-bs4-alert

// prismjs
@import ~prismjs/themes/prism
// @import ~prismjs/plugins/toolbar/prism-toolbar
@import ~prismjs/plugins/line-numbers/prism-line-numbers
@import ~prismjs/plugins/line-highlight/prism-line-highlight
@import ~prismjs/plugins/match-braces/prism-match-braces

// lightbox
@import ~ngx-lightbox/lightbox

div.lightboxOverlay
    position: fixed
div.lightbox
    display: flex !important
    flex-direction: column-reverse
div.lb-dataContainer
    padding-top: 0 !important
    padding-bottom: 10px !important
    a.lb-zoomOut,
    a.lb-zoomIn
        cursor: pointer

$c1: #c00000 // コード（変数、属性）
$c2: #ed7d31 // コード（組込命令、関数定義・呼出）
$c3: #4472c4 // コード（文字列）
$c4: #ff0000 // コード（PHPタグ、即値、予約語）
$c5: #7030a0 // コード（HTML、条件分岐、公開範囲）
$c6: #538135 // コード（定数、アノテーション）
$c7: #222222 // puctuation

.token
    &.variable,
    &.attr-name,
    &.keyword
        color: $c1
    &.class-name,
    &.keyword-echo
        color: $c2
    &.string
        color: $c3
    &.important,
    &.number,
    &.boolean
        color: $c4
    &.keyword-public,
    &.keyword-private,
    &.keyword-protected,
    &.keyword-if,
    &.keyword-else,
    &.keyword-while,
    &.keyword-for
        color: $c5
    &.annotation,
    &.constant,
    &.keyword-static,
    &.keyword-final
        color: $c6
    &.punctuation
        color: $c7
    &.operator
        color: $c4
        background: none

div, span, label
    user-select: none
    -moz-user-select: none
    -ms-user-select: none
    -khtml-user-select: none
    -webkit-user-select: none

.text-selectable, code, code span
    user-select: text
    -moz-user-select: text
    -ms-user-select: text
    -khtml-user-select: text
    -webkit-user-select: text

.clickable
    cursor: pointer
    outline: none

.text-prewrap
    white-space: pre-wrap

// iOS bug fix
.iframe-container
    overflow-y: auto
    -webkit-overflow-scrolling: touch

    iframe
        width: 100%
        height: 100%
        display: block
        border: none

html
    overflow-y: scroll

div.contents_wrap
    padding-top: 80px
    padding-bottom: 20px
    max-width: 100%

nav > div.container
    max-width: 100%
    padding-left: 0
    padding-right: 0

.modal-open
    padding-right: 0px !important

.modal-open nav
    padding-right: 16px !important

.modal
    padding-right: 0px !important

button, button:focus
    outline: none

.btn
    touch-action: manipulation

select
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none

.badge
    vertical-align: bottom

.sort-active
    cursor: auto
    color: darkorange !important

.fas, .far
    display: inline
    font-size: 20px

.fas.nav, .far.nav
    font-size: 18px

.fas.arrow, .far.arrow
    font-size: 24px
    vertical-align: text-top

.fas.white, .far.white
    color: white

.fas.darkgray, .far.darkgray
    color: darkgray

.fas.dimgray, .far.dimgray
    color: dimgray

.fas.lightslategray, .far.lightslategray
    color: lightslategray

.fas.rt135
    transform: rotate(135deg)

a.no-decoration, a.no-decoration:hover
    text-decoration: none
    color: white

nav.navbar
    background-color: $theme-color
    border-bottom: 1px solid $theme-color //rgba(0, 0, 0, 0.125)
    box-shadow: 0 3px 4px rgba($black, 0.2)

    a.navbar-brand
        width: 40px
        height: 36px
        background-color: $body-bg
        border-radius: 6px
        background-image: url("/assets/img/digskill_mark.svg")
        background-size: auto 30px
        background-repeat: no-repeat
        background-position: center center
        &::after
            content: 'Dig Skill'
            font-size: 28px
            margin: 50px
            padding: 0
            line-height: 1rem

.input-group-text
    background-color: $theme-color
    color: $white

.was-validated .custom-control-input:valid ~ .custom-control-label
    color: #111111

.card-header
    padding: 0.5rem 1rem

.card-toggler
    cursor: pointer

span.card-toggler i.rt180
    display: inline-block
    transition: transform 0.3s ease-out
    transform: rotate(-360deg)
    -ms-transform: rotate(-360deg)
    -moz-transform: rotate(-360deg)
    -webkit-transform: rotate(-360deg)
    -o-transform: rotate(-360deg)

span.card-toggler.collapsed i.rt180
    display: inline-block
    transition: transform 0.3s ease-out
    transform: rotate(180deg)
    -ms-transform: rotate(180deg)
    -moz-transform: rotate(180deg)
    -webkit-transform: rotate(180deg)
    -o-transform: rotate(180deg)

span.card-toggler i.rt45
    display: inline-block
    transition: transform 0.3s ease-out
    transform: rotate(-360deg)
    -ms-transform: rotate(-360deg)
    -moz-transform: rotate(-360deg)
    -webkit-transform: rotate(-360deg)
    -o-transform: rotate(-360deg)

span.card-toggler.collapsed i.rt45
    display: inline-block
    transition: transform 0.3s ease-out
    transform: rotate(135deg)
    -ms-transform: rotate(135deg)
    -moz-transform: rotate(135deg)
    -webkit-transform: rotate(135deg)
    -o-transform: rotate(135deg)

.modal-header
    padding: 0.5rem 1rem

.popover
    z-index: 10000

.blink
    animation: anime-blink 0.3s ease-in-out 3 alternate

@keyframes anime-blink
    0%
        opacity: 0
    100%
        opacity: 1

div#mask
    position: fixed
    top: 0px
    left: 0px
    z-index: 9999
    background: black
    -moz-opacity: 0.4
    opacity: 0.4
    filter: Alpha(opacity = 40)
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    height: 100vh
    width: 100vw

div#now_loading
    font-size: 16px
    font-weight: bold
    color: white

button#dummy_button
    border: none
    background-color: rgba(0,0,0,0)

$bt_row_border: 1
$bt_row_height: 32

div#bookingTable
    div
        .bt_cell
            border: ($bt_row_border + px) solid white
            padding: 0 4px
            height: $bt_row_height + px
            font-size: 0.9em
            border-radius: 3px

        .bt_th
            color: #ffffff
            background-color: #75c29b

        .bt_today
            background-color: #ff9363

        .bt_sat
            background-color: #80aad1

        .bt_sun
            background-color: #f29494

        .bt_td
            background-color: #e2efda

        .bt_close
            background-color: #d9d9d9

        .bt_expired
            background-color: #ffe1e1

        .bt_empty
            background-color: #b8d5ef

        .bt_full
            background-color: #b8d5ef

        .bt_booked
            background-color: #f4b084

        .bt_attendance
            background-color: #b8d5ef
            color: #00008b

        .bt_absence
            background-color: #b8d5ef
            color: #b22222

        .bt_now
            background-color: #f4b084

        .bt_rows_2
            height: $bt_row_height * 2 + px

        .bt_rows_3
            height: $bt_row_height * 3 + px

        .bt_rows_4
            height: $bt_row_height * 4 + px

        .bt_rows_5
            height: $bt_row_height * 5 + px

        .bt_rows_6
            height: $bt_row_height * 6 + px

.read_more
    &:after
        content: '続きを読む'
    &.open:after
        content: '続きを閉じる'
    & + div
        display: none
    &.open + div
        display: block

div#info ul
    margin: 0
    padding: 0 0 0 1.0rem
    & > li > ol
        padding: 0 0 0 1.5rem

div#info ol
    margin: 0
    padding: 0 0 0 1.0rem

span.ilb
    span
        display: inline-block
div#toast-container
    div.toast-warning
        position: relative
        margin-bottom: 90px
        background-color: #343a40
        color: #FFFFFF
        line-height: 30px
        background-image: none
        border: none
        opacity: 0.6!important
        &:before
            font-family: 'Font Awesome 5 Free'
            content: '\f063'
            font-weight: 900
            font-size: 1.8rem
            position: absolute
            left: 12px
            top: 50%
            transform: translate(0, -50%)
        button.toast-close-button
            color: #FFFFFF
            text-shadow: none
            opacity: 1
            &:hover
                opacity: 0.5
