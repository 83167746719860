// Body
$body-bg: #f8fafc;
$body-color: #111;

// Typography
//$font-size-base: 0.9rem;

// Default colors
$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;
$black:   #000000;
$white:   #ffffff;

// Overwrite colors
$blue:    #3490dc;
$indigo:  #6574cd;
$purple:  #9561e2;
$pink:    #f66D9b;
$red:     #e3342f;
$orange:  #f6993f;
$yellow:  #ffed4a;
$green:   #38c172;
$teal:    #4dc0b5;
$cyan:    #6cb2eb;

$teal:    #75c29b;

// Theme color
$theme-color: $teal;

// navbar
$navbar-light-color:                rgba($white, 0.9);
$navbar-light-hover-color:          rgba($white, 1.0);
$navbar-light-active-color:         rgba($white, 1.0);
$navbar-light-disabled-color:       rgba($black, 0.3);
$navbar-light-toggler-border-color: rgba($white, 0.5);

// card
$card-cap-color: $white;
$card-cap-bg:    $theme-color;

// pagination
$pagination-color:               $theme-color;
$pagination-hover-color:         $theme-color;
$pagination-hover-border-color:  $theme-color;
$pagination-hover-bg:            $white;
$pagination-active-bg:           $theme-color;
$pagination-active-border-color: $theme-color;

color pallet {
  color: #db4006;
  color: #4261ca;
  color: #4bbeb4;
  color: #fe9b00;

  color: #ea4515;
  color: #167cd8;
  color: #4bb4b4;
  color: #f4c00b;

  color: #ef7552;
  color: #149eea;
  color: #6bc1c0;
  color: #f9db6c;
}
